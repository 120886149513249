<template>
  <CRow>
    <CCol col="12" lg="6">
      <AItemViewer :options="{ id: $route.params.id, url: 'admin/invoice_formats', route: '/admin/property/invoice_formats', name: 'invoice_format' }" />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'InvoiceFormat'
}
</script>
